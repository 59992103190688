.staking-element{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin: 16px 16px 78px;
    max-width: 300px;
    flex-grow: 2;
    position: relative;
    /*overflow: hidden;*/
}
.nft-video{
    overflow: hidden;
}

.nft-video-container{
    position: relative;
}

.price{
    position: absolute;
    left: 8px;
    top: calc(100% + 4px);
}

.buy-button{
    transition: all 0.3s;
    outline: none;
    border: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: scale(0.5);
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-appearance: none;
}

.nft-video-container:hover .buy-button{
    opacity: 1;
    transform: scale(1);
}

.buy-button.paywall{
    opacity: 1;
    transform: scale(1);
}

.total{
    position: absolute;
    right: 8px;
    bottom: 8px;
    width: 40px;
    text-align: center;
}

.buy-button:focus{
    outline: none;
}

.owned-marker{
    position: absolute;
    font-size: 16px;
    font-weight: bolder;
    bottom: calc(100%);
    left: -2px;
    padding: 0px 4px;
}

.border-t-1{
    border: 2px solid rgba(192, 192, 192, 1);
}
.border-t-1 .owned-marker{
    color: rgba(192, 192, 192, 1);
}

.border-t-2{
    border: 2px solid rgba(0, 128, 0, 1);
}
.border-t-2 .owned-marker{
    color: rgba(0, 128, 0, 1);
}

.border-t-3{
    border: 2px solid rgb(66, 66, 255);
}
.border-t-3 .owned-marker{
    color: rgb(66, 66, 255);
}


.border-t-4{
    border: 2px solid rgb(178, 19, 178);
}
.border-t-4 .owned-marker{
    color: rgb(178, 19, 178);
}

.border-t-5{
    box-shadow: 5px 5px 20px 10px rgba(255, 215, 0, 0.2), -5px -5px 20px 10px rgba(128, 0, 128, 0.2);
}

.border-t-5 .owned-marker{
    color: rgba(255, 215, 0, 1);
}


@media (max-width: 1000px) {
    .staking-element{
        max-width: calc(50% - 16px);
    }
}

@media (max-width: 695px) {
    .staking-element{
        max-width: 100%;
    }
}